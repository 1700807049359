import jwtDecode from 'jwt-decode';
import { ApiFactory } from './Api';

const api = new ApiFactory();

async function fetchContracts(token) {
    const decoded = jwtDecode(token);
    const response = await api.get(`/customers/${decoded.id}/contracts`);
    const contracts = response.data.contracts;
    return contracts;
}

async function checkContract(contractId, hasViewAccess) {
    const response = await api.get(`/contracts/${contractId}/check?has_view_access=${hasViewAccess}`);
    return response.data.role;
}

async function fetchContractDetail(contractId) {
    const response = await api.get(`/contracts/${contractId}`);
    return response.data;
}

export { fetchContracts, checkContract, fetchContractDetail };
