export const SET_PROFILE = '@user/SET_PROFILE';
export const DELETE_PROFILE = '@user/DELETE_PROFILE';

const initialState = {
    profile: null
};

export function user(state = initialState, action) {
    switch (action.type) {
        case SET_PROFILE:
            return {
                ...state,
                profile: action.profile
            };
        case DELETE_PROFILE:
            return {
                ...state,
                profile: null
            };
        default:
            return state;
    }
}
