import jwtDecode from 'jwt-decode';
import { ApiFactory } from './Api';

const api = new ApiFactory();

async function fetchCustomerInfo() {
    const token = localStorage.getItem('token');
    if (token) {
        const customer = jwtDecode(token);
        const response = await api.get(`/customers/${customer.id}`);
        return response.data;
    }
}

async function editProfile(request) {
    const token = localStorage.getItem('token');
    if (token) {
        const customer = jwtDecode(token);
        const response = await api.put(`/customers/${customer.id}/edit_profile`, request);
        return response.data;
    }
}

export { fetchCustomerInfo, editProfile };
