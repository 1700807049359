import { ApiConst } from '../constants'
import QueryString from 'query-string'

export class ApiFactory {
  constructor () {
    this.defaultConfig = {
      headers: {
        Accept: 'application/json'
      }
    }
    this.defaultConfig.headers['Content-Type'] = 'application/json'
  }
  
  getCredential = config => {
    const extraHeaders = {}
    
    const token = localStorage.getItem('token')
    if (token) {
      extraHeaders.Authorization = `${ApiConst.TOKEN_PREFIX} ${token}`
    }

    config.headers = Object.assign(config.headers, extraHeaders)
    return config
  }

  request (config, url) {
    config = this.getCredential(config)
    return fetch(process.env.REACT_APP_API_BASE_URL + url, config)
      .then(response => {
        if (!response.ok) {
          return response.json()
          .then(err => Promise.reject({
            statusCode: response.status,
            ...err.error
          }))
        }

        if (response.status === 204) return {};
        return response.json().then(json => {
          return json
        })
      }
    )
  }

  get (url, data = null) {
    let config = {}
    config = Object.assign(config, this.defaultConfig)
    config.method = 'GET'
    config.body = undefined
    if (data) {
      url += '?' + QueryString.stringify(data)
    }
    return this.request(config, url)
  }

  post (url, data) {
    const config = Object.assign({}, this.defaultConfig)
    config.method = 'POST'
    if (FormData && data instanceof FormData) {
      delete config.headers['Content-Type']
    } else {
      data = JSON.stringify(data)
    }
    config.body = data
    return this.request(config, url)
  }

  put (url, data) {
    const config = Object.assign({}, this.defaultConfig)
    config.method = 'PUT'
    if (FormData && data instanceof FormData) {
      delete config.headers['Content-Type']
    } else {
      data = JSON.stringify(data)
    }
    config.body = data
    return this.request(config, url)
  }

  patch (url, data) {
    const config = Object.assign({}, this.defaultConfig)
    config.method = 'PATCH'
    if (FormData && data instanceof FormData) {
      delete config.headers['Content-Type']
    } else {
      data = JSON.stringify(data)
    }
    config.body = data
    return this.request(config, url)
  }

  delete (url, data) {
    const config = Object.assign({}, this.defaultConfig)
    config.method = 'DELETE'
    config.headers['Content-Length'] = 0;
    if (FormData && data instanceof FormData) {
      delete config.headers['Content-Type']
    } else {
      data = JSON.stringify(data)
    }
    config.body = data
    return this.request(config, url)
  }


  extractFilename(disposition) {
    var filename = 'UnTitle'
    if (disposition && disposition.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      var matches = filenameRegex.exec(disposition)
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '')
      }
    }
    return filename
  }

  download(url, data = null, queryString = true) {
    let config = {}
    config = Object.assign(config, this.defaultConfig)
    config.method = 'GET'
    if (data) {
      if (queryString) {
        url += '?' + QueryString.stringify(data)
      } else {
        url += '?' + JSON.stringify(data)
      }
    }
    let filename = null;
    config = this.getCredential(config);
    return new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_API_BASE_URL + url, config)
      .then(
        (res) => {
          const disposition = res.headers.get('Content-Disposition')
          filename = this.extractFilename(disposition)
          return res.blob()
        },
        (error) => {
          reject(error)
        }
      )
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
        resolve();
      })
    })
  }
}
