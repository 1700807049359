import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function Toast({ isOpen, onChange, message, variant }) {
    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={5000}
            onClose={() => onChange(false)}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
        >
            <Alert onClose={() => onChange(false)} severity={variant} variant="filled" sx={{ width: '100%' }}>
                {message || 'エラーが発生しました。再度試してください。'}
            </Alert>
        </Snackbar>
    );
}

export default Toast;
