// LoadingContext.js
import React, { createContext, useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = `
      @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
      }
    `;
        document.head.appendChild(style);

        return () => {
            document.head.removeChild(style);
        };
    }, []);

    const LoadingModal = () => {
        if (!isLoading) return null;

        return (
            <div style={styles.overlay}>
                <div style={styles.modal}>
                    <CircularProgress />
                </div>
            </div>
        );
    };

    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            animation: 'fadeIn 0.5s ease-in-out',
            zIndex: 9999
        },
        modal: {
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
            textAlign: 'center'
        }
    };

    return (
        <LoadingContext.Provider value={{ setIsLoading }}>
            {children}
            <LoadingModal />
        </LoadingContext.Provider>
    );
};

export default LoadingContext;
