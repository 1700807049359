import Toast from '../components/Toast';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

const ToastContext = createContext({
    isOpen: false
});
const UseToastContext = createContext({
    showToast: () => {}
});

function ToastProvider({ children }) {
    const [isOpen, setIsOpen] = useState(false);
    const [variant, setVariant] = useState();
    const [message, setMessage] = useState();
    const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);

    const ToastContextValue = useMemo(
        () => ({
            isOpen,
            message,
            variant
        }),
        [isOpen, message, variant]
    );

    const useToastContextValue = useMemo(
        () => ({
            showToast: (message, variant = 'error') => {
                setIsOpen(true);
                setVariant(variant);
                setMessage(message);
            }
        }),
        [setIsOpen, setMessage, setVariant]
    );

    return (
        <ToastContext.Provider value={ToastContextValue}>
            <UseToastContext.Provider value={useToastContextValue}>
                {children}
                {ToastContextValue.isOpen && (
                    <Toast
                        isOpen={ToastContextValue.isOpen}
                        message={ToastContextValue.message}
                        variant={ToastContextValue.variant}
                        onChange={() => onClose()}
                    />
                )}
            </UseToastContext.Provider>
        </ToastContext.Provider>
    );
}

export default ToastProvider;
export function useToast() {
    return useContext(UseToastContext);
}
