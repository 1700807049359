import React from 'react';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from './../../../config';
import Logo from './../../../components/Logo';
import { useFromQueryParam } from '../../../helpers/getFromQueryParam';

//-----------------------|| MAIN LOGO ||-----------------------//

const LogoSection = ({ disabled }) => {
    const from = useFromQueryParam();
    const portalUrl = process.env.REACT_APP_PORTAL_URL;
    const linkPath = from === 'cis' ? portalUrl : config.defaultPath;
    return (
        <ButtonBase disableRipple component="a" href={linkPath} disabled={disabled}>
            <Logo />
        </ButtonBase>
    );
};

export default LogoSection;
