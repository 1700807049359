import { Card, Typography, Link, Box } from '@mui/material';
import NotFoundImage from '../assets/images/not_found.png';
import { useFromQueryParam } from '../helpers/getFromQueryParam';

function ErrorPage() {
    const from = useFromQueryParam();
    return (
        <div style={{ paddingTop: '64px' }}>
            <Card style={{ textAlign: 'center', padding: '30px 20px', borderRadius: '25px' }}>
                <img src={NotFoundImage} style={{ maxWidth: 150 }} alt="logo" />
                <Typography variant="h2" mt={1}>
                    URLが無効になりました。
                </Typography>
                <Typography fontSize={18} mt={3} mb={1} px={3}>
                    もう一度最初からお申込み手続きを進めてください。
                </Typography>
                {from !== 'app' && (
                    <Box mt={3}>
                        <Link
                            fontSize={22}
                            underline="hover"
                            href={`${window.location.origin}/login`}
                            onClick={() => {
                                localStorage.removeItem('token');
                            }}
                            style={{
                                fontWeight: 700
                            }}
                        >
                            TOPページへ
                        </Link>
                    </Box>
                )}
            </Card>
        </div>
    );
}

export default ErrorPage;
